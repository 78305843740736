/* SCROLLBARS */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: @blue;
}
::-webkit-scrollbar-thumb:window-inactive {
  .setOpacity(0.3);
}

/* PACE */
.pace {
  -webkit-pointer-events: none;
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: @blue;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

// common forms part
form {
  .elem {
    position: relative;
    &:before {
      font-family: @fontAwesomeName;
      position: absolute;
      width: 10px;
      height: 10px;
      font-size: 15px;
      left: 555px;
      top: 11px;
      pointer-events: none;
      content: " ";
      .transition(all, 0.1s, ease-out);
      .transform(scale(0));
    }
  }
  textarea {
    font-family: Arial, Helvetica, sans-serif;
  }
  .field-ok-select,
  .field-ok {
    label {
      color: @colorSuccess;
      &.input i.ico {
        color: @colorSuccess;
      }
    }
    input,
    select,
    textarea,
    .input {
      border-color: @colorSuccess !important;
    }
    &:before {
      content: "\f00c";
      color: @colorSuccess;
      font-weight: 900;
      .transform(scale(1));
    }

    &.elem {
      &.elem-select {
        &:after {
          color: @colorSuccess;
        }
        &:before {
          content: "";
        }
      }
    }
  }
  .field-error,
  .field-error-select {
    label {
      color: @colorError;
      &.input i.ico {
        color: @colorError;
      }
    }
    input,
    select,
    textarea,
    .input {
      border-color: @colorError !important;
    }
    &:before {
      content: "\f071";
      color: @colorError;
      font-weight: 900;
      .transform(scale(1));
    }
    &.elem {
      &.elem-select {
        &:after {
          color: @colorError;
        }
        &:before {
          content: "";
        }
      }
    }
  }
}

.fc-error,
.errors {
  margin: -12px 0 10px 231px;
  p,
  li {
    color: @colorError;
    font-size: 12px;
    display: flex;
    margin-top: 5px;
    &:before {
      content: "\f071";
      font-family: @fontAwesomeName;
      padding-right: 5px;
      display: inline-block;
      font-weight: 900;
    }
    span {
      margin-left: 10px;
    }
  }
}

/* CLASSES TO HIDE ELEMENTS
-----------------------------------------*/

.tablet-hidden {
  .media(0, 1012, {display: none;});
}

.tablet-visible {
  display: none;
  .media(0, 1012, {display: block;});
}

.mobile-hidden {
  .media(0, 767, {display: none;});
}

.mobile-visible {
  display: none;
  .media(0, 767, {display: block;});
}

#main-container {
  @media (max-width: @mainBreakpoint) {
    overflow-x: hidden;
  }
}

.full-width {
  width: 100% !important;
}

/*FLEXBOX STYLES
------------------------------------------------*/

.d-flex {
  display: flex;
}

.flex-wrap {
    flex-wrap: wrap;
}

.justify-content-center {
  justify-content: center;
}

.flex-direction-column {
  flex-direction: column;
}

.align-items-center {
  align-items: center;
}

.text-center {
  text-align: center !important;
}

.pull-none {
  float: none !important;
}

button {
  border: none;
  background: none;
  box-sizing: border-box;
  padding: 0;
  cursor: pointer;
  outline: 0;
  .transition();
  &:hover {
    color: darken(@blue, 10%);
  }
  &:active {
    transform: translate(0px, 3px);
    -webkit-transform: translate(0px, 3px);
  }
}

/* TOAST */
.toast-container {
  position: fixed;
  top: 0px;
  right: 15px;
  left: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  z-index: 9009;
}
.toast,
.alert {
  color: #fff;
  display: inline-block;
  position: relative;
  font-size: 0;
  width: 100%;
  .transition(all, 0.4s, ease-out);

  &.toast {
    .setOpacity(0);
    .boxShadow(0, 0, 70px, 0, rgba(0, 0, 0, 0.3));

    &.toast-inline {
      .setOpacity(1);
      .boxShadow(0, 0, 0, 0, rgba(0, 0, 0, 0));
    }

  }

  .msg {
    padding: 15px 20px 15px 10px;
    display: inline-flex;
    font-size: 14px;
    align-items: center;
    width: ~"calc(100% - 32px)";
    vertical-align: top;
    &:before {
      font-family: @fontAwesomeName;
      color: inherit;
      font-size: 25px;
      width: 40px;
      display: inline-block;
      text-align: center;
      vertical-align: middle;
      margin-right: 10px;
      font-weight: 900;
    }
    a {
      text-decoration: underline;
      font-weight: bold;
      color: rgba(0, 0, 0, 0.5);
    }
  }
  button {
    width: 21px;
    height: 21px;
    margin: 15px 10px 0 0;
    color: inherit;
    font-size: 20px;
    display: inline-block;
  }
  .timer {
    display: block;
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.2s);
  }
  &.success,
  &.online {
    background: @colorSuccess;
    .msg:before {
      content: "\f00c";
    }
    &.online .msg:before {
      content: "\f1eb";
    }
  }
  &.error {
    background: @colorError;
    .msg:before {
      content: "\f071";
    }
  }
  &.warning,
  &.offline {
    background: @colorWarning;
    button {
      display: none;
    }
    .msg:before {
      content: "\f12a";
    }
    &.offline {
      .msg:before {
        content: "\f1eb";
      }
      .msg:after {
        display: block;
        width: 3px;
        height: 38px;
        left: 30px;
        position: absolute;
        background: #fff;
        content: "";
        .transform(rotate(45deg));
        .boxShadow(0, 0, 0, 2px, @colorWarning);
        .borderRadius(50%);
      }
    }
  }
  &.message {
    background: @colorInfo;
    .msg:before {
      content: "\f129";
    }
  }
}

/* LOADER */
.waiting {
  position: fixed;
  z-index: 9999;
  visibility: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: fade(@blue, 80%);
  .setOpacity(0);
  .transition();
  &.open {
    visibility: visible;
    .setOpacity(1);
  }
  .waiting-inside {
    width: 148px;
    height: 100px;
    position: absolute;
    margin: -50px 0 0 -74px;
    left: 50%;
    top: 60%;
    .loader {
      width: 148px;
      height: 100px;
      top: 0;
      left: 0;
      position: absolute;
      &:after {
        content: "";
        top: auto;
        position: absolute;
        display: block;
        bottom: 0em;
        left: 0;
        height: 0.25em;
        width: 1em;
        background-color: #fff;
        .setOpacity(0.3);
        .animation(shadow, 1.2s, infinite, linear);
        .roundedCorners(50%);
      }
    }
    .roller,
    .roller:last-child {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 0;
      left: 0;
      .rotate(135deg);
      .animation(rollercoaster, 1.2s, infinite, linear);
    }
    .roller:last-child {
      left: auto;
      right: 0;
      .rotate(-45deg);
      .animation(rollercoaster2, 1.2s, infinite, linear);
    }
    .roller:before,
    .roller:last-child:before {
      content: "";
      display: block;
      width: 15px;
      height: 15px;
      background: #fff;
      border-radius: 50%;
    }
    #loader2:after,
    #loader2 .roller {
      -webkit-animation-delay: 0.15s;
      animation-delay: 0.15s;
    }
    #loader3:after,
    #loader3 .roller {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
    }
  }
}

.disabled {
  .setOpacity(0.3) !important;
  pointer-events: none;
}

.disabled-actions {
  .setOpacity(0.3) !important;
}

.disabled-value {
  .setOpacity(0.6);
  text-decoration: line-through;
}
