/*  mixins  */
.setOpacity(@opac: 0) {
    opacity: @opac;
    -moz-opacity: @opac;
    -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=@opac * 100)";
}

.roundedCorners(@radius: 5px) {
    border-radius: @radius;
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
}

.borderRadius(@radiusTopRight: 5px, @radiusBottomRight: 5px, @radiusBottomLeft: 5px, @radiusTopLeft: 5px) {
    -webkit-border-top-left-radius: @radiusTopLeft;
    -webkit-border-top-right-radius: @radiusTopRight;
    -webkit-border-bottom-right-radius: @radiusBottomRight;
    -webkit-border-bottom-left-radius: @radiusBottomLeft;
    -moz-border-radius-topleft: @radiusTopLeft;
    -moz-border-radius-topright: @radiusTopRight;
    -moz-border-radius-bottomright: @radiusBottomRight;
    -moz-border-radius-bottomleft: @radiusBottomLeft;
    border-top-left-radius: @radiusTopLeft;
    border-top-right-radius: @radiusTopRight;
    border-bottom-right-radius: @radiusBottomRight;
    border-bottom-left-radius: @radiusBottomLeft;
}

.boxShadow(@x, @y, @blur, @spread, @color) {
    box-shadow: @arguments;
    -webkit-box-shadow: @arguments;
}

.boxShadowInset(@x, @y, @blur, @spread, @color) {
    box-shadow: inset @arguments;
    -webkit-box-shadow: inset @arguments;
}

.transition(@type: all, @sec: 0.2s, @trans: linear) {
    transition: @arguments;
    -moz-transition: @arguments;
    -webkit-transition: @arguments;
}

.rotate(@deg) {
    transform: rotate(@arguments);
    -moz-transform: rotate(@arguments);
    -webkit-transform: rotate(@arguments);
}

.transform(@string) {
    -webkit-transform: @string;
    -moz-transform: @string;
    -ms-transform: @string;
    transform: @string;
}

.animation(@name, @sec, @duration, @trans) {
    -webkit-animation: @arguments;
    -moz-animation: @arguments;
    animation: @arguments;
}

.fonts(@name, @file) {
    font-family: @name;
    src: url(/fonts/@{file}.eot);
    src: url(/fonts/@{file}.woff) format('woff'),
         url(/fonts/@{file}.ttf) format('truetype');
}

.gradient (@startColor: #eee, @endColor: white) {
    background: -webkit-gradient(linear, left top, left bottom, from(@startColor), to(@endColor));
    background: -webkit-linear-gradient(top, @startColor, @endColor);
    background: -moz-linear-gradient(top, @startColor, @endColor);
    background: -ms-linear-gradient(top, @startColor, @endColor);
    background: -o-linear-gradient(top, @startColor, @endColor);
}
.horizontal-gradient (@startColor: #eee, @endColor: white) {
    background-image: -webkit-gradient(linear, left top, right top, from(@startColor), to(@endColor));
    background-image: -webkit-linear-gradient(left, @startColor, @endColor);
    background-image: -moz-linear-gradient(left, @startColor, @endColor);
    background-image: -ms-linear-gradient(left, @startColor, @endColor);
    background-image: -o-linear-gradient(left, @startColor, @endColor);
}

.repeatingGradient(@startColor, @endColor, @deg: 45deg) {
    background: -webkit-repeating-linear-gradient(@deg, @startColor, @startColor 10px, @endColor 10px, @endColor 20px) top left fixed;
    background: repeating-linear-gradient(@deg, @startColor, @startColor 10px, @endColor 10px, @endColor 20px) top left fixed;
}

//padding 25 - 0
.padding-loop (@i) when (@i >= 0) {
    .padding-@{i} {
        padding: ~"@{i}px";
    }
    .padding-loop(@i - 5);
}
.padding-loop (25);

//padding-top 25 - 0
.padding-top-loop (@i) when (@i >= 0) {
    .padding-top-@{i} {
        padding-top: ~"@{i}px";
    }
    .padding-top-loop(@i - 5);
}
.padding-top-loop (25);

//padding-bottom 25 - 0
.padding-bottom-loop (@i) when (@i >= 0) {
    .padding-bottom-@{i} {
        padding-bottom: ~"@{i}px";
    }
    .padding-bottom-loop(@i - 5);
}
.padding-bottom-loop (25);

//padding-left 25 - 0
.padding-left-loop (@i) when (@i >= 0) {
    .padding-left-@{i} {
        padding-left: ~"@{i}px";
    }
    .padding-left-loop(@i - 5);
}
.padding-left-loop (25);

//padding-right 25 - 0
.padding-right-loop (@i) when (@i >= 0) {
    .padding-right-@{i} {
        padding-right: ~"@{i}px";
    }
    .padding-right-loop(@i - 5);
}
.padding-right-loop (25);

//margin-top 25 - 0
.margin-top-loop (@i) when (@i >= 0) {
    .margin-top-@{i} {
        margin-top: ~"@{i}px";
    }
    .margin-top-loop(@i - 5);
}
.margin-top-loop (25);

//margin-bottom 25 - 0
.margin-bottom-loop (@i) when (@i >= 0) {
    .margin-bottom-@{i} {
        margin-bottom: ~"@{i}px";
    }
    .margin-bottom-loop(@i - 5);
}
.margin-bottom-loop (25);

//margin-left 25 - 0
.margin-left-loop (@i) when (@i >= 0) {
    .margin-left-@{i} {
        margin-left: ~"@{i}px";
    }
    .margin-left-loop(@i - 5);
}
.margin-left-loop (25);

//margin-right 25 - 0
.margin-right-loop (@i) when (@i >= 0) {
    .margin-right-@{i} {
        margin-right: ~"@{i}px";
    }
    .margin-right-loop(@i - 5);
}
.margin-right-loop (25);

@unit: px;
.media(@minWidth; @maxWidth; @rules) {
    @media (min-width: ~"@{minWidth}@{unit}") and (max-width: ~"@{maxWidth}@{unit}") {
        @rules();
    }
}