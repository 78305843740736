@import "values.less";
@import "mixins.less";
@import "animations.less";

@import "toggler.less";

@import "clear.less";
@import "common.less";

@boxWidth: 475px;

.spacer {
  content: " ";
  display: block;
  clear: both;
  height: 1px;
  font-size: 1px;
}

.modal-waiting {
  background-position: 50% 30% !important;
  z-index: 99999999999 !important;
}

/* sticky */
body,
html,
#content,
.grid-100 {
  height: 100%;
}

a {
  text-decoration: none;
  text-align: center;
  color: #7b7b7b;
  .transition();
  &:hover {
    color: #0088cc;
  }
}

/* SITE */
body {
  font-size: 12px;
  font-family: "Roboto", "Open Sans", sans-serif;
  color: #4d4d4d;
  background: url("/images/default/bg.jpg") no-repeat center center fixed;
  background-size: cover;
  @media (max-width: @boxWidth) {
    background: #fff;
  }
}

#content {
  display: flex;
  justify-content: center;
}

#content-bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
  .gradient(rgba(33, 150, 243, 1), rgba(33, 150, 243, 0.6));
  @media (max-width: @boxWidth) {
    display: none;
  }
}

/* white box */
.box {
  background: #fff;
  padding: 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: @boxWidth;
  min-width: @boxWidth;
  overflow-y: auto;
  overflow-x: hidden;
  .boxShadow(0, 0, 70px, 0, rgba(0, 0, 0, 0.3));
  @media (max-width: @boxWidth) {
    width: 100%;
    min-width: 100%;
    padding-top: 60px;
    .boxShadow(0, 0, 0, 0, rgba(0, 0, 0, 0));
  }
}

.logo {
  width: 60%;
  display: block;
  margin: 30px auto;
  flex: 1;
  img {
    width: 100%;
  }
}

h1 {
  font-weight: 100;
  font-size: 40px;
  @media (max-width: 870px) {
    text-align: center;
    font-size: 30px;
  }
}

h2 {
  color: @colorSuccess;
  font-size: 20px;
  text-align: center;
  margin: 0 15px;
  p {
    margin-top: 10px;
    font-size: 16px;
  }
}

/* tabs */
.tabs-menu {
  width: @boxWidth;
  flex: 1;
  ul {
    display: flex;
    justify-content: stretch;
    li {
      width: 100%;
      a {
        display: block;
        width: 100%;
        text-align: center;
        padding: 20px;
        font-size: 25px;
        background: @background;
        color: #000;
        .transition();
        font-weight: 100;
      }
      &.active {
        a {
          background: #fff;
        }
      }
    }
  }
  @media (max-width: @boxWidth) {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    ul {
      li {
        a {
          font-size: 16px;
        }
      }
    }
  }
}

/* main text */
.main-text {
  font-size: 35px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 100px;
  width: 950px;
  span {
    font-size: 25px;
    font-weight: 100;
  }
  h1 {
    font-size: 100px;
  }
  .pre {
    font-size: 12px;
    color: #000;
    background: #fff;
  }
  @media (max-width: 1024px) {
    padding: 0 30px;
  }
  @media (max-width: 870px) {
    display: none;
  }
}

/* buttons */
.btn {
  padding: 12px 18px;
  font-size: 15px;
  border: 0;
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  width: 100%;
  text-align: center;
  background-color: @blue;
  display: block;
  .transition(all, 0.1s, linear);
  &:hover {
    color: #fff;
    background-color: darken(@blue, 10%);
  }
  &:active {
    transform: translate(0px, 3px);
    -webkit-transform: translate(0px, 3px);
  }
}

.btn-link {
  display: block;
  margin: 15px auto;
  padding: 10px;
}

/* form */
form {
  width: 70%;
  margin: 0 auto 0;
  flex: 5;
  input[type="text"],
  input[type="email"],
  input[type="password"],
  textarea,
  select {
    margin: 20px 0;
    height: 40px;
    border: 1px solid @grey;
    background-color: #fff;
    width: 100%;
    padding: 0 10px;
    outline: 0;
    .transition();
    &:focus {
      border-color: @blue;
    }
  }
  select {
    margin-top: 10px;
  }
  .elem {
    &:before {
      left: auto;
      right: 15px;
    }
  }
  .login-checkbox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 10px;
    .toggler {
      margin-left: 12px;
    }
    .errors {
      display: none;
    }
    > label {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      &.required {
        span:after {
          content: " *";
          color: @colorError;
        }
      }
    }
  }
  textarea {
    max-width: 100%;
    min-width: 100%;
    width: 100%;
    min-height: 200px;
    padding: 15px;
  }
  @media (max-width: @boxWidth) {
    width: 85%;
  }
}

/* errors & alerts */
.fc-error,
.errors {
  margin: -15px 0 5px 0;
}
.errors-chk {
  .errors {
    margin-top: 5px;
  }
}
.fc-error.errors-chk {
  margin-top: 5px;
}

/* footer */
.gnstudio {
  margin-bottom: 10px;
}

.success {
  flex: 6;
}

/* check icon */
.checkmark {
  width: 150px;
  height: 150px;
  min-width: 150px;
  min-height: 150px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0px 0px 0px @colorSuccess;
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;

  &__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: @colorSuccess;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  &__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 100px @colorSuccess;
  }
}
