/* FADE IN */
@-webkit-keyframes fadeIn {
    0% { opacity: 0; top: -20px; }
    60% { opacity: 0; top: -20px; }
    100% { opacity: 1; top: 0px; }
}
@-moz-keyframes fadeIn {
    0% { opacity: 0; top: -20px; }
    60% { opacity: 0; top: -20px; }
    100% { opacity: 1; top: 0px; }
}

/* swing */
@-webkit-keyframes swing {
    0%   { left: calc(50%-50px); }
    20%  { left: calc(45%-50px); }
    40%  { left: calc(55%-50px); }
    60%  { left: calc(45%-50px); }
    80%  { left: calc(55%-50px); }
    100% { left: calc(50%-50px); }
}

@-moz-keyframes swing {
    0%   { left: calc(50%-50px); }
    20%  { left: calc(45%-50px); }
    40%  { left: calc(55%-50px); }
    60%  { left: calc(45%-50px); }
    80%  { left: calc(55%-50px); }
    100% { left: calc(50%-50px); }
}

/* show toggler */
@keyframes showToggler {
    0% { .setOpacity(0); }
    50% { .setOpacity(0); }
    100% { .setOpacity(1) }
}

/* turn on toggler */
@keyframes activeKnob {
    20% { width: 50%; }
    90% { width: 0; }
}

/* turn off toggler */
@keyframes inactiveKnob {
    40% { width: 50%; }
    60% { width: 0; }
}

/* fly in */
@-moz-keyframes fly-in {
    0% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
        .setOpacity(0);
    }
    100% {
        -moz-transform: translateY(0px);
        transform: translateY(0px);
        .setOpacity(1);
    }
}
@-webkit-keyframes fly-in {
    0% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
        .setOpacity(0);
    }
    100% {
        -webkit-transform: translateY(0px);
        transform: translateY(0px);
        .setOpacity(1);
    }
}
@keyframes fly-in {
    0% {
        -webkit-transform: translateY(-30px);
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        transform: translateY(-30px);
        .setOpacity(0);
    }
    100% {
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
        transform: translateY(0px);
        .setOpacity(1);
    }
}

/* loader */
@-webkit-keyframes rollercoaster {
    0% { -webkit-transform: rotate(135deg); }
    8% { -webkit-transform: rotate(240deg); }
    20% { -webkit-transform: rotate(300deg); }
    40% { -webkit-transform: rotate(380deg); }
    45% { -webkit-transform: rotate(440deg); }
    50% { -webkit-transform: rotate(495deg); opacity: 1; }
    50.1% { -webkit-transform: rotate(495deg); opacity: 0; }
    100% { -webkit-transform: rotate(495deg); opacity: 0; }
}

@-webkit-keyframes rollercoaster2 {
    0% { opacity: 0; }
    49.9% { opacity: 0; }
    50% { opacity: 1; -webkit-transform: rotate(-45deg); }
    58% { -webkit-transform: rotate(-160deg); }
    70% { -webkit-transform: rotate(-240deg); }
    80% { -webkit-transform: rotate(-300deg); }
    90% { -webkit-transform: rotate(-340deg); }
    100% { -webkit-transform: rotate(-405deg); }
}

@-webkit-keyframes shadow {
    0% { opacity: .3; -webkit-transform: translateX(65px) scale(0.5, 0.5); }
    8% { -webkit-transform: translateX(30px) scale(2, 2); }
    13% { -webkit-transform: translateX(0px) scale(1.3, 1.3); }
    30% { -webkit-transform: translateX(-15px) scale(0.5, 0.5); opacity: 0.1; }
    50% { -webkit-transform: translateX(60px) scale(1.2, 1.2); opacity: 0.3; }
    60% { -webkit-transform: translateX(130px) scale(2, 2); opacity: 0.05; }
    65% { -webkit-transform: translateX(145px) scale(1.2, 1.2); }
    80% { -webkit-transform: translateX(120px) scale(0.5, 0.5); opacity: 0.1; }
    90% { -webkit-transform: translateX(80px) scale(0.8, 0.8); }
    100% { -webkit-transform: translateX(60px); opacity: 0.3; }
}

@-moz-keyframes rollercoaster {
    0% { -moz-transform: rotate(135deg); }
    8% { -moz-transform: rotate(240deg); }
    20% { -moz-transform: rotate(300deg); }
    40% { -moz-transform: rotate(380deg); }
    45% { -moz-transform: rotate(440deg); }
    50% { -moz-transform: rotate(495deg); opacity: 1; }
    50.1% { -moz-transform: rotate(495deg); opacity: 0; }
    100% { -moz-transform: rotate(495deg); opacity: 0; }
}

@-moz-keyframes rollercoaster2 {
    0% { opacity: 0; }
    49.9% { opacity: 0; }
    50% { opacity: 1; -moz-transform: rotate(-45deg); }
    58% { -moz-transform: rotate(-160deg); }
    70% { -moz-transform: rotate(-240deg); }
    80% { -moz-transform: rotate(-300deg); }
    90% { -moz-transform: rotate(-340deg); }
    100% { -moz-transform: rotate(-405deg); }
}

@-moz-keyframes shadow {
    0% { opacity: .3; -moz-transform: translateX(65px) scale(0.5, 0.5); }
    8% { -moz-transform: translateX(30px) scale(2, 2); }
    13% { -moz-transform: translateX(0px) scale(1.3, 1.3); }
    30% { -moz-transform: translateX(-15px) scale(0.5, 0.5); opacity: 0.1; }
    50% { -moz-transform: translateX(60px) scale(1.2, 1.2); opacity: 0.3; }
    60% { -moz-transform: translateX(130px) scale(2, 2); opacity: 0.05; }
    65% { -moz-transform: translateX(145px) scale(1.2, 1.2); }
    80% { -moz-transform: translateX(120px) scale(0.5, 0.5); opacity: 0.1; }
    90% { -moz-transform: translateX(80px) scale(0.8, 0.8); }
    100% { -moz-transform: translateX(60px); opacity: 0.3; }
}

@keyframes rollercoaster {
    0% { transform: rotate(135deg); }
    8% { transform: rotate(240deg); }
    20% { transform: rotate(300deg); }
    40% { transform: rotate(380deg); }
    45% { transform: rotate(440deg); }
    50% { transform: rotate(495deg); opacity: 1; }
    50.1% { transform: rotate(495deg); opacity: 0; }
    100% { transform: rotate(495deg); opacity: 0; }
}

@keyframes rollercoaster2 {
    0% { opacity: 0; }
    49.9% { opacity: 0; }
    50% { opacity: 1; transform: rotate(-45deg); }
    58% { transform: rotate(-160deg); }
    70% { transform: rotate(-240deg); }
    80% { transform: rotate(-300deg); }
    90% { transform: rotate(-340deg); }
    100% { transform: rotate(-405deg); }
}

@keyframes shadow {
    0% { opacity: .3; transform: translateX(65px) scale(0.5, 0.5); }
    8% { transform: translateX(30px) scale(2, 2); }
    13% { transform: translateX(0px) scale(1.3, 1.3); }
    30% { transform: translateX(-15px) scale(0.5, 0.5); opacity: 0.1; }
    50% { transform: translateX(60px) scale(1.2, 1.2); opacity: 0.3; }
    60% { transform: translateX(130px) scale(2, 2); opacity: 0.05; }
    65% { transform: translateX(145px) scale(1.2, 1.2); }
    80% { transform: translateX(120px) scale(0.5, 0.5); opacity: 0.1; }
    90% { transform: translateX(80px) scale(0.8, 0.8); }
    100% { transform: translateX(60px); opacity: 0.3; }
}

/* pulse */
@-webkit-keyframes pulseRed {
    0%   { -webkit-box-shadow: 0 0 0 0 fade(@colorError, 40); }
    70%  { -webkit-box-shadow: 0 0 0 10px fade(@colorError, 0); }
    100% { -webkit-box-shadow: 0 0 0 0 fade(@colorError, 0); }
}
@keyframes pulseRed {
    0% {
        -moz-box-shadow: 0 0 0 0 fade(@colorError, 40);
        box-shadow: 0 0 0 0 fade(@colorError, 40);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px fade(@colorError, 0);
        box-shadow: 0 0 0 10px fade(@colorError, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 fade(@colorError, 0);
        box-shadow: 0 0 0 0 fade(@colorError, 0);
    }
}

@-webkit-keyframes pulseYellow {
    0%   { -webkit-box-shadow: 0 0 0 0 fade(@colorWarning, 40); }
    70%  { -webkit-box-shadow: 0 0 0 10px fade(@colorWarning, 0); }
    100% { -webkit-box-shadow: 0 0 0 0 fade(@colorWarning, 0); }
}
@keyframes pulseYellow {
    0% {
        -moz-box-shadow: 0 0 0 0 fade(@colorWarning, 40);
        box-shadow: 0 0 0 0 fade(@colorWarning, 40);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px fade(@colorWarning, 0);
        box-shadow: 0 0 0 10px fade(@colorWarning, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 fade(@colorWarning, 0);
        box-shadow: 0 0 0 0 fade(@colorWarning, 0);
    }
}

@-webkit-keyframes pulseBlue {
    0%   { -webkit-box-shadow: 0 0 0 0 fade(@blue, 40); }
    70%  { -webkit-box-shadow: 0 0 0 10px fade(@blue, 0); }
    100% { -webkit-box-shadow: 0 0 0 0 fade(@blue, 0); }
}
@keyframes pulseBlue {
    0% {
        -moz-box-shadow: 0 0 0 0 fade(@blue, 40);
        box-shadow: 0 0 0 0 fade(@blue, 40);
    }
    70% {
        -moz-box-shadow: 0 0 0 10px fade(@blue, 0);
        box-shadow: 0 0 0 10px fade(@blue, 0);
    }
    100% {
        -moz-box-shadow: 0 0 0 0 fade(@blue, 0);
        box-shadow: 0 0 0 0 fade(@blue, 0);
    }
}

/* spin */
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }