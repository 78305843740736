body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote {
    margin:0;
    padding:0;
    display:block;
    box-sizing: border-box;
}
input,textarea,select {
    outline: none;
}
input[type="checkbox"]{
    display:inline;
}

input:-webkit-autofill {
    background-color: #B9D2E8 !important;
}

a, button {
    box-sizing: border-box;
}
a, a:active, a:visited, a:focus {
    text-decoration: none;
    outline: 0;
}

th,td {
    margin:0;
    padding:0;
}

table {
    border-collapse:collapse;
    border-spacing:0;
}
fieldset,img {
    border:0;
    margin:0;
    padding:0;
}
address,caption,cite,code,dfn,em,th,var {
    font-style:normal;
    font-weight:normal;
}
ol,ul {
    list-style:none;
}
caption,th {
    text-align:left;
}
h1,h2,h3,h4,h5,h6 {
    font-size:100%;
    font-weight:normal;
}
q:before,q:after {
    content:'';
}
abbr,acronym {
    border:0;
}
dt{ display:none;}